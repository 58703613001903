import React from 'react';
import classnames from 'classnames';

const CenteredText = ({ large, bold, children, className }) => {
  return (
    <div className={classnames(className, 'text-center', { 'large-text': large, 'font-bold': bold })}>{children}</div>
  );
};

export default CenteredText;
