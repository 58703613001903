import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Section from '../components/shared/section';
import Heading from '../components/shared/heading';
import CenteredText from '../components/shared/centred-text';
import { setPage, setIsMobile, setIsTablet } from '../store/app';
import t from '../locales';
import Flex from '../components/shared/flex';
import PricingSvg from '../images/pricing.svg';
import Button from '../components/shared/button';

const Pricing = ({ isMobile, isTablet, setPage }) => {
  useEffect(() => {
    setPage('pricing');
  }, [setPage]);

  const isSmallScreen = isMobile || isTablet;

  return (
    <div>
      <Section id="banner" className="bg-black pricing" inverted>
        <div className="flex" />
      </Section>
      <Heading inverted> {t('title.pricing')} </Heading>
      <Section>
        <Flex column={isSmallScreen}>
          {isSmallScreen && <img src={PricingSvg} alt="Pricing" className="my-0 mx-auto w-5/6" />}
          <div className={classnames('text-center large-text text-grey-darker', isSmallScreen ? 'w-full' : 'w-1/2')}>
            <h1 className="pricing-header">
              <span>{t('pricing.expandStable')}</span>
              <span className="whitespace-no-wrap">
                {` `}
                {t('pricing.withHorsemanago')}
              </span>
            </h1>
            <div>{t('pricing.complexManage')}</div>
            <div>{t('pricing.ifYouAreSearching')}</div>
            <div>{t('pricing.organizationOfStable')}</div>
            <div>{t('pricing.testForFree')}</div>
            <CenteredText className="mb-6">
              <span>{t('pricing.payForTheSystem')}</span>
              <span className="price-span"> {t('pricing.priceForMonth')}</span>
              <span>{t('pricing.noLimitOfHorses')}</span>
            </CenteredText>
            <Button className="teal" to="/register" small={!isSmallScreen}>
              {t('general.registerTheStable')}
            </Button>
          </div>
          {!isSmallScreen && (
            <Flex className="w-1/2">
              <img src={PricingSvg} alt="Pricing" className="my-0 mx-auto w-5/6" />
            </Flex>
          )}
        </Flex>
      </Section>
    </div>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
    isTablet: state.app.isTablet,
    isMobile: state.app.isMobile,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
    setIsTablet: isTablet => dispatch(setIsTablet(isTablet)),
  })
)(Pricing);
